<template>
    <a-modal v-model="visible" :footer="null" title="页面授权" width="900px">
        <a-popconfirm
            title="确认授权？"
            ok-text="是"
            cancel-text="否"
            @confirm="grantRoute"
            style="margin-bottom: 10px;"
        >
            <a-button type="primary">点击授权</a-button>
        </a-popconfirm>
        <a-table
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,  getCheckboxProps: getCheckboxProps}" rowKey="routeCode" 
       >
        <span slot="allowDerived" slot-scope="text, record">{{record.allowDerived ? '是' : '否'}}</span>
        </a-table>
     
    </a-modal>
</template>

<script>
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
import { queryUserRouteTreeApi, queryRoleModulesApi, grantRouteToRoleApi } from '@/api/role'
export default {
    data() {
        return {
            selectedRows: [],
            currentUserModules: [],
            selectedRowKeys: [],
            loading: false,
            visible: false,
            data: [],
            rowSelection,
            expandedRowKeys: [],
            columns: [
                {
                    title: '页面编码',
                    dataIndex: 'routeCode',
                    key: 'routeCode'
                },
                {
                    title: '页面描述',
                    dataIndex: 'routeName',
                    key: 'routeName'
                },
                {
                    title: '是否允许派生',
                    dataIndex: 'allowDerived',
                    scopedSlots: { customRender: 'allowDerived' },
                },
            ],
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0
            },
            record: {}
        }
    },
    methods: {
        queryRoleModules() {
            queryRoleModulesApi({roleCode: this.record.roleCode}).then(res => {
                this.currentUserModules = res.result
                console.log(this.currentUserModules, '---')
                this.selectedRowKeys = this.currentUserModules
            })
        },
        getCheckboxProps(record){
            return ({
                props: {
                    disabled: !record.allowDerived
                }
            })
        },
        grantRoute(){
            grantRouteToRoleApi({routeCodes: this.selectedRowKeys, roleCode: this.record.roleCode}).then(res => {
                this.$message.success('操作成功')
                this.selectedRows = []
                this.selectedRowKeys = []
                this.queryRoleModules()
            }).catch(() => {
                this.$message.error('操作失败')
            })
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys;
            this.selectedRows = selectedRows
        },
        showRoute(record) {
            this.visible = true
            this.record = record
            this.selectedRowKeys = []
            this.queryUserRouteTree()
        },
        queryUserRouteTree() {
            this.loading = true
            queryUserRouteTreeApi().then(res => {
                this.data = res.result
                this.loading = false
                this.queryRoleModules(this.record)
            })
        },
        handleExpand() {}
    }
}
</script>