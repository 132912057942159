<template>
  <div class="content">
      <a-form layout="inline">
            <a-form-item label="角色名">
              <a-input placeholder="请输入关键词" v-model="params.roleName" allowClear/>
            </a-form-item>
            <a-form-item >
              <a-button type="primary" @click="onSearch">查询</a-button>
              <a-button type="default" @click="addRole"  style="margin-left: 10px;">新增</a-button>
                <a-popconfirm
                title="确认删除？"
                ok-text="是"
                cancel-text="否"
                @confirm="deleteByIds"
                style="margin-left: 10px;"
                >
                    <a-button type="danger"  style="margin-left: 10px;">批量删除</a-button>
                </a-popconfirm>
            </a-form-item>
      </a-form>
      <a-table  
        :scroll="{  y: 640, x: 1700 }" 
        :columns="columns"
        :data-source="tableData"
        style="margin-top: 20px;"
        :loading="loading"
        :pagination="pagination"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" rowKey="id"
        >
        <span slot="action" slot-scope="text, record">
            <a @click="showGrantRoute(record)">页面授权</a>
            <a @click="showEdit(record)"  style="margin-left: 10px;">编辑</a>
            <a-popconfirm
                title="确认删除？"
                ok-text="是"
                cancel-text="否"
                @confirm="deleteById(record)"
                style="margin-left: 10px;"
                >
                    <a >删除</a>
            </a-popconfirm>
        </span>
      </a-table>
      <route ref="route" @query="queryRoleMgtList"/>
      <edit ref="edit" @query="queryRoleMgtList"/>
  </div>
</template>
<script>
import edit from './component/edit'
import route from './component/route'
import { queryRoleMgtListApi , deleteRoleMgtApi} from '@/api/role'
export default{
    components: { route , edit},
    data() {
        return {
            selectedRows: [],
            selectedRowKeys: [],
            loading: false,
            tableData: [],
            columns: [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id'
                },
                {
                    title: '角色编码',
                    dataIndex: 'roleCode',
                    key: 'roleCode'

                },
                {
                    title: '角色名称',
                    dataIndex: 'roleName',
                    key: 'roleName'
                },
                {
                    title: '角色描述',
                    dataIndex: 'represent',
                    key: 'represent'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                }
            ],
            params: {},
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                    return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
            },
            deleteIds: []
        }
    },
    mounted() {
        this.queryRoleMgtList()
    },
    methods: {
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys;
            this.selectedRows = selectedRows
        },
        addRole(){
            this.$refs.edit.showEdit({roleName: '', represent: '', id: ''})
        },
        deleteById(record) {
            this.deleteIds = []
            this.deleteIds.push(record.id)
            this.deleteRoles()
        },
        deleteByIds() {
            this.deleteIds  = this.selectedRowKeys
            this.deleteRoles()
        },
        deleteRoles() {
            deleteRoleMgtApi(this.deleteIds).then(res => {
                this.queryRoleMgtList()
                this.$message.success('删除成功')
            }).catch(() => {
                this.$message.error('删除失败')
            })
        },
        showEdit(record){
            this.$refs.edit.showEdit(record)
        },
        onSearch() {
            this.pagination.current = 1
            this.queryRoleMgtList()
        },
        showGrantRoute(record){
            this.$refs.route.showRoute(record)
        },
        queryRoleMgtList() {
            this.redayQuery()
            queryRoleMgtListApi({...this.params}).then(res => {
                this.tableData = res.result.content
                this.endQuery(res)
            })
        },
        redayQuery() {
            this.loading = true
            this.params.page = this.pagination.current
            this.params.limit = this.pagination.pageSize
        },
        endQuery(res) {
            this.loading = false
            this.pagination.current = res.result.pageNo + 1
            this.pagination.total = res.result.totalElements
        }
    }
}
</script>