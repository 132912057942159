<template>
    <a-modal v-model="visible" title="修改信息" okText="保存" @ok="handleOk">
        <p>角色名称: <a-input placeholder="输入角色名称" v-model="record.roleName"/></p>
        <p>角色描述: <a-input placeholder="输入角色描述" v-model="record.represent"/></p>
    </a-modal>
</template>

<script>
import {updateRoleMgtInfoApi} from '@/api/role'
export default {
    data() {
        return{
            record: {},
            visible: false
        }
    },
    methods: {
        showEdit(record) {
            this.visible = true
            this.record = record
        },
        handleOk() {
            updateRoleMgtInfoApi({...this.record}).then(res => {
                if(res.code === '0') {
                    this.$message.success(this.record.id ? '修改成功' : '新增角色成功')
                    this.visible = false
                    this.$emit('query')
                } else {
                    this.$message.error('新增失败')
                }
            }).catch(() => {
                this.$message.error(this.record.id ? '修改失败' : '新增角色失败')
            })
        }
    }
}
</script>